import upperFirst from 'lodash/upperFirst'
import moment from 'moment'
import {createAction} from 'redux-act'
import {combineEpics, ofType} from 'redux-observable'
import {mergeMap} from 'rxjs/operators'
import {v4} from 'uuid'
import {flatCopyComposite} from '../../modules/dataGrid/utils/utils'
import {getPermanentCompositeCopy} from '../../utils/compositeUtils'
import {
  loadFromLocalStorage,
  loadUserInfo,
  saveSelectedRestaurant,
  saveToLocalStorage,
  saveUserInfo
} from '../../utils/localStorage'
import {
  addUserOptimistic,
  postUser,
  putUser,
  updateUserOptimistic,
  updateUsersOptimistic
} from '../modules/users'
import {setEditedUser} from './admin'
import {
  selectAllPreviewRecipes,
  selectCompositeByIdFromUrl,
  selectIsPreviewRecipeCopy
} from '../selectors/selectors'
import {
  addOrUpdateBusinessOptimistically,
  postBusiness,
  putBusiness,
  setSelectedBusiness
} from './businesses'
import {
  addOrUpdateBusinessPartnerOptimistically,
  postBusinessPartner,
  putBusinessPartner
} from './businessPartners'
import {exportRecipesCsv} from './exports'
import {
  closeSelectPreviewCollectionModal,
  hideAddOrEditBusinessPartnerModal,
  hideAddOrEditOrganisationModal,
  hideAddOrEditRestaurantModal,
  hideSearchModal
} from './modal'
import {
  addRecipeOptimistic,
  deleteRecipe,
  deleteRecipeOptimistic,
  postRecipe
} from './recipes'
import {
  postRestaurant,
  putRestaurant,
  setEditedRestaurant,
  setSelectedRestaurant,
  updateRestaurantsOptimistic
} from './restaurants'

export const processAddOrEditBusinessFormSubmit = createAction(
  'forms/PROECESS_ADD_BUSINESS_PARTNER_FORM_SUBMIT'
)

export const processAddOrEditOrganisationFormSubmit = createAction(
  'forms/PROECESS_ADD_ORGANISATION_FORM_SUBMIT'
)

export const processAddOrEditRestaurantFormSubmit = createAction(
  'forms/PROECESS_ADD_RESTAURANT_FORM_SUBMIT'
)

export const processAddUserFormSubmit = createAction(
  'forms/PROECESS_ADD_USER_FORM_SUBMIT'
)

export const processBillingFormSubmit = createAction(
  'forms/PROCESS_BILLIG_FORM_SUBMIT'
)

export const processLetsAddSubmit = createAction(
  'forms/epics/PROCESS_LETS_ADD_SUBMIT'
)

export const processUserSettingsFormSubmit = createAction(
  'forms/epics/PROCESS_USER_SETTINGS_FORM_SUBMIT'
)

export const processRestaurantSettingsFormSubmit = createAction(
  'forms/epics/PROCESS_RESTAURANT_SETTINGS_FORM_SUBMIT'
)

export const processBillingDetailsFormSubmit = createAction(
  'forms/epics/PROCESS_BILLING_DETAILS_FORM_SUBMIT'
)

export const processExportsFormSubmit = createAction(
  'forms/epics/PROCESS_EXPORTS_FORM_SUBMIT'
)

export const proccessPreviewCollectionFormSubmit = createAction(
  'forms/epics/PROCESS_PREVIEW_COLLECTION_FORM_SUBMIT'
)

const processBillingFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processBillingFormSubmit().type),
    mergeMap(({payload: {selectedPricingScheme, values}}) => {
      const {
        config: {webEntryDomain},
        businesses: {selectedBusiness},
        restaurants: {selectedRestaurant},
        users: {user}
      } = state$.value

      const oneMonthFromNow = moment
        .utc()
        .add(1, 'month')
        .format('YYYY-MM-DD')

      const oneYearFromNow = moment
        .utc()
        .add(1, 'year')
        .format('YYYY-MM-DD')

      let updatedLicenses
      if (selectedPricingScheme === 'personal') {
        updatedLicenses = {
          ...selectedRestaurant.licenses,
          chef: webEntryDomain === 'eaternity' ? oneYearFromNow : '',
          eaternity: '',
          klimateller: webEntryDomain === 'klimateller' ? oneMonthFromNow : '',
          klimatellerTrialUsed: webEntryDomain === 'klimateller',
          licensePurchased: true,
          voucher: values.vouchercode ? values.vouchercode : '',
          selectedPricingScheme
        }
      } else if (
        selectedPricingScheme === 'professional' ||
        selectedPricingScheme === 'enterprise'
      ) {
        updatedLicenses = {
          ...selectedRestaurant.licenses,
          chef: '',
          eaternity: webEntryDomain === 'eaternity' ? oneYearFromNow : '',
          klimateller: webEntryDomain === 'klimateller' ? oneYearFromNow : '',
          licensePurchased: true,
          voucher: values.vouchercode ? values.vouchercode : '',
          selectedPricingScheme
        }
      } else {
        throw new Error('no or wrong pricingScheme selected')
      }

      const updatedRestaurant = {
        ...selectedRestaurant,
        additionalInfo: values.additionalInfo || '',
        addressAdditionalInfo: values.addressAdditionalInfo || '',
        city: values.city,
        dailyGuests: values.dailyGuests,
        licenses: updatedLicenses,
        // selectedRestaurant.name might still be the email which was removed
        // from the initial values to not confuse the user... Added back here.
        name: values.name || selectedRestaurant.name,
        shortName: values.shortName,
        postalCode: values.postalCode,
        restaurantType: values.restaurantType,
        street: values.street
      }

      const updatedBusiness = {
        ...selectedBusiness,
        name: values.name || selectedBusiness.name,
        shortName: values.shortName || '',
        city: values.city,
        street: values.street,
        type: 'business',
        addressAdditionalInfo: values.addressAdditionalInfo || '',
        postalCode: values.postalCode,
        billingFirstName: values.firstName,
        billingLastName: values.lastName,
        billingPersonPosition: values.position || '',
        billingCostCenter: values.costCenter || '',
        billingCity: values.city,
        billingStreet: values.street,
        billingAddressAdditionalInfo: values.addressAdditionalInfo || '',
        billingAdditionalInfo: values.additionalInfo || '',
        billingPostalCode: values.postalCode
      }

      const updatedUser = {
        ...user,
        firstName: values.firstName,
        lastName: values.lastName
      }

      saveToLocalStorage('user', {
        ...loadFromLocalStorage('user'),
        user: updatedUser
      })
      saveSelectedRestaurant(updatedRestaurant)
      saveToLocalStorage('selectedBusiness', updatedBusiness)
      saveToLocalStorage('billingModalOpen', false)

      // WARNING: do not setSelectedRestaurant here! Instead it is automatically done later in putRestaurantEpic when the request returns.
      let actions = [
        updateRestaurantsOptimistic(updatedRestaurant),
        putRestaurant({
          restaurant: updatedRestaurant,
          emailData: {
            sendLicenseRequestEmail: true,
            email: user.email,
            firstName: values.firstName,
            lastName: values.lastName,
            selectedPricingScheme
          }
        }),
        setSelectedBusiness(updatedBusiness),
        putBusiness(updatedBusiness),
        updateUserOptimistic(updatedUser),
        putUser({data: {user: updatedUser}})
      ]

      if (selectedPricingScheme !== 'personal') {
        actions = [...actions, hideSearchModal()]
      }

      return of(...actions)
    })
  )

const processLetsAddSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processLetsAddSubmit().type),
    mergeMap(({payload: values}) => {
      const {
        businesses: {selectedBusiness},
        restaurants: {selectedRestaurant}
      } = state$.value

      // save name on both business and restaurant
      let updatedRestaurant = {
        ...selectedRestaurant,
        termsAccepted: values.termsAccepted
      }

      let updatedBusiness = {
        ...selectedBusiness
      }

      if (values.name) {
        updatedBusiness = {
          ...updatedBusiness,
          name: values.name
        }
        updatedRestaurant = {
          ...updatedRestaurant,
          name: values.name
        }
      }

      saveSelectedRestaurant(updatedRestaurant)
      saveToLocalStorage('selectedBusiness', updatedBusiness)
      saveToLocalStorage('billingModalOpen', true)

      return of(
        updateRestaurantsOptimistic(updatedRestaurant),
        setSelectedRestaurant(updatedRestaurant),
        putRestaurant({restaurant: updatedRestaurant}),
        setSelectedBusiness(updatedBusiness),
        putBusiness(updatedBusiness)
      )
    })
  )

const processAddOrEditOrganisationFormSubmitEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processAddOrEditOrganisationFormSubmit().type),
    mergeMap(({payload: {selectedBusiness, values}}) => {
      const updatedBusinessFields = {
        name: values.name,
        shortName: values.shortName || '',
        city: values.city,
        street: values.street,
        type: 'business',
        addressAdditionalInfo: values.addressAdditionalInfo || '',
        postalCode: values.postalCode,
        billingFirstName: values.firstName,
        billingLastName: values.lastName,
        billingPersonPosition: values.position || '',
        billingCostCenter: values.costCenter || '',
        billingCity: values.city,
        billingStreet: values.street,
        billingAddressAdditionalInfo: values.addressAdditionalInfo || '',
        billingAdditionalInfo: values.additionalInfo || '',
        billingPostalCode: values.postalCode
      }

      const updatedBusiness = selectedBusiness
        ? {...selectedBusiness, ...updatedBusinessFields, admins: []}
        : {id: v4(), ...updatedBusinessFields, admins: []}

      let actions = [
        hideAddOrEditOrganisationModal(),
        addOrUpdateBusinessOptimistically(updatedBusiness),
        setSelectedBusiness(undefined)
      ]

      if (selectedBusiness) {
        actions = [...actions, putBusiness(updatedBusiness)]
      } else {
        actions = [...actions, postBusiness(updatedBusiness)]
      }

      return of(...actions)
    })
  )

const processAddUserFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processAddUserFormSubmit().type),
    mergeMap(({payload: {t, values}}) => {
      const {
        admin: {editedUser},
        businesses: {selectedBusiness},
        restaurants: {editedRestaurant},
        users: {
          user: {
            email: adminEmail,
            firstName: adminFirstName,
            lastName: adminLastName
          }
        }
      } = state$.value

      const translatedEmailText = {
        greeting: `${upperFirst(t('global.welcome'))}!`,
        paragraph1: t('email.invite.paragraph1', {
          firstName: adminFirstName || adminEmail,
          lastName: adminLastName,
          organisation: selectedBusiness.name
        }),
        buttonText: t('email.invite.buttonText'),
        linkInfoText: t('email.invite.linkInfoText'),
        paragraph2: t('email.invite.paragraph2'),
        paragraph3: t('email.invite.paragraph3'),
        closing1: t('email.invite.closing1'),
        closing2: t('email.invite.closing2')
      }

      let actions = []
      if (editedUser) {
        const updatedUser = {
          ...editedUser,
          ...values
        }

        actions = [
          ...actions,
          setEditedUser(updatedUser),
          updateUsersOptimistic(updatedUser),
          putUser({
            data: {user: updatedUser, translatedEmailText},
            resendInvitationEmail: values.sendEmail
          })
        ]
      } else {
        const newUser = {
          id: v4(),
          emailConfirmed: false,
          ...values
        }

        actions = [
          ...actions,
          addUserOptimistic({...newUser, type: 'user'}),
          postUser({
            businessId: selectedBusiness.id,
            restaurantId: editedRestaurant.id,
            data: {
              translatedEmailText,
              user: newUser
            }
          })
        ]
      }

      return of(...actions)
    })
  )

const processAddOrEditRestaurantFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processAddOrEditRestaurantFormSubmit().type),
    mergeMap(({payload: values}) => {
      const {
        businesses: {selectedBusiness},
        restaurants: {editedRestaurant}
      } = state$.value

      let actions = []
      let newOrUpdatedRestaurant
      if (editedRestaurant) {
        newOrUpdatedRestaurant = {
          ...editedRestaurant,
          ...values,
          type: 'restaurant'
        }
        actions = [
          ...actions,
          putRestaurant({restaurant: newOrUpdatedRestaurant})
        ]
      } else {
        newOrUpdatedRestaurant = {...values, id: v4(), type: 'restaurant'}
        actions = [
          ...actions,
          postRestaurant({
            businessId: selectedBusiness.id,
            restaurant: newOrUpdatedRestaurant
          })
        ]
      }

      return of(
        ...actions,
        updateRestaurantsOptimistic(newOrUpdatedRestaurant),
        hideAddOrEditRestaurantModal(),
        setEditedRestaurant(undefined)
      )
    })
  )

const processUserSettingsFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processUserSettingsFormSubmit().type),
    mergeMap(({payload: values}) => {
      const {
        users: {user}
      } = state$.value

      const updatedUser = {
        ...user,
        ...values
      }

      const userInfo = loadUserInfo()
      saveUserInfo({...userInfo, user: updatedUser})

      return of(
        updateUserOptimistic(values),
        putUser({data: {user: updatedUser}})
      )
    })
  )

const processRestaurantSettingsFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processRestaurantSettingsFormSubmit().type),
    mergeMap(({payload: values}) => {
      const {
        restaurants: {selectedRestaurant}
      } = state$.value

      const updatedRestaurant = {
        ...selectedRestaurant,
        ...values
      }

      saveSelectedRestaurant(updatedRestaurant)

      return of(
        updateRestaurantsOptimistic(updatedRestaurant),
        setSelectedRestaurant(updatedRestaurant),
        putRestaurant({restaurant: updatedRestaurant})
      )
    })
  )

const processBillingDetailsFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processBillingDetailsFormSubmit().type),
    mergeMap(({payload: values}) => {
      const {
        businesses: {selectedBusiness}
      } = state$.value

      const updatedBusiness = {
        ...selectedBusiness,
        ...values,
        billingAdditionalInfo: values.additionalInfo,
        billingFirstName: values.firstName,
        billingLastName: values.lastName,
        billingCostCenter: values.costCenter,
        billingPersonPosition: values.position
      }

      saveToLocalStorage('selectedBusiness', updatedBusiness)

      return of(
        setSelectedBusiness(updatedBusiness),
        putBusiness(updatedBusiness)
      )
    })
  )

const processAddOrEditBusinessFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(processAddOrEditBusinessFormSubmit().type),
    mergeMap(({payload: values}) => {
      const {
        businessPartners: {selectedBusinessPartner}
      } = state$.value
      const {admins: adminsFormValues, name} = values

      const updatedAdmins = adminsFormValues
        ? adminsFormValues.map(({value}) => JSON.parse(value))
        : []

      const newOrUpdatedBusinessPartner = selectedBusinessPartner
        ? {...selectedBusinessPartner, name, admins: updatedAdmins}
        : {id: v4(), name, admins: updatedAdmins}

      const putOrPostActionCreator = selectedBusinessPartner
        ? putBusinessPartner
        : postBusinessPartner

      return of(
        addOrUpdateBusinessPartnerOptimistically(newOrUpdatedBusinessPartner),
        putOrPostActionCreator(newOrUpdatedBusinessPartner),
        hideAddOrEditBusinessPartnerModal()
      )
    })
  )

const processExportsFormSubmitEpic = (action$, _, {of}) =>
  action$.pipe(
    ofType(processExportsFormSubmit().type),
    mergeMap(({payload: values}) => {
      return of(exportRecipesCsv(values))
    })
  )

const proccessPreviewCollectionFormSubmitEpic = (action$, state$, {of}) =>
  action$.pipe(
    ofType(proccessPreviewCollectionFormSubmit().type),
    mergeMap(({payload: values}) => {
      const recipe = selectCompositeByIdFromUrl(state$.value)
      const isPreviewRecipeCopy = selectIsPreviewRecipeCopy(state$.value)

      let actions
      if (isPreviewRecipeCopy) {
        // delete recipe from selected preview collections
        const allPreviewRecipes = selectAllPreviewRecipes(state$.value)
        const recipeCopiesToDelete = values.collections.flatMap(({value}) => {
          const previewCollectionRecipes = allPreviewRecipes.filter(
            recipe => recipe.recipeCollectionId === value
          )
          const previewCollectionCopy = getPermanentCompositeCopy(
            previewCollectionRecipes,
            recipe
          )

          return previewCollectionCopy ? [previewCollectionCopy] : []
        })

        actions = recipeCopiesToDelete.flatMap(recipe => [
          deleteRecipeOptimistic({recipe}),
          deleteRecipe({recipe})
        ])
      } else {
        // add recipe to selected preview collections
        actions = values.collections
          .map(({value}) => ({
            ...flatCopyComposite(recipe),
            permanent: true,
            recipeCollectionId: value
          }))
          .flatMap(recipe => [
            postRecipe(recipe),
            addRecipeOptimistic({recipe})
          ])
      }

      return of(...actions, closeSelectPreviewCollectionModal())
    })
  )

export const formEpics = combineEpics(
  processAddOrEditBusinessFormSubmitEpic,
  processAddOrEditOrganisationFormSubmitEpic,
  processAddOrEditRestaurantFormSubmitEpic,
  processAddUserFormSubmitEpic,
  processBillingFormSubmitEpic,
  processBillingDetailsFormSubmitEpic,
  processExportsFormSubmitEpic,
  processLetsAddSubmitEpic,
  proccessPreviewCollectionFormSubmitEpic,
  processRestaurantSettingsFormSubmitEpic,
  processUserSettingsFormSubmitEpic
)
